body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brandLogo {
  width: 12em;
  margin-top: 20px;
  margin-left: 0px;
  margin-bottom: 20px;
}

.brandLogoFooter {
  width: 15em;
  margin-bottom: 40px;
}

.footer {
  background-color: #4D6B79;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0px;
}

.footer a:link {
  color: #ffffff;
}

.heroImage {
  width: 100%;
}

.yellowArrowBullet {
  width: 25px;
  margin: 10px;
  padding-bottom: 15px;
}

.titleText {
  font-size: 28px;
  line-height: 10%;
}

.weBuildImg {
  width: 250px;
  margin-bottom: 40px;
}

p {
  font-size: 18px;
  line-height: 130%;
}
ul {
  font-size: 18px;
  line-height: 130%;
}

.innovSolutions {
  background-color: #F4F4F5;
  padding-bottom: 0;
  margin: 0;
}

.contactTeam {
  background-color: #ffffff;
  padding-bottom: 40px;
  margin: 0;
}

.bgCurveShape {
  width: 100%;
  padding: 0%;
  margin: 0;
}

.card {
  min-width: 250px;
  max-width: 300px;
  text-align: left;
}

.cardImg {
  min-width: 200px;
  max-width: 270px;
}

.btn-primary {
  background-color: #536A77;
  border: 0;
}

.blackText-responsive {
  font-size: calc(100% + 1.2vw + 1.2vh);
  color: #000000;
  font-weight: 300;
  line-height: 120%;
  padding-left: 50px;
  padding-bottom: 30px;
}

.blackText-responsive-center {
  font-size: calc(100% + 1.5vw + 1.5vh);
  color: #000000;
  font-weight: 200;
  line-height: 120%;
  padding-bottom: 30px;
  text-align: center;
}

.softDevCardImg {
  width: 175px;
  margin-left: auto;
  margin-right: auto;
}

.softDevCard {
  max-width: 360px;
  min-width: 360px;
  margin: 10px;
  margin-bottom: 30px;
  height: auto;
  padding: 25px;
  border-radius: 10px;
}

.needTraining {
  background-color: #F4F4F5;
  padding-bottom: 0;
  margin: 0;
}

.greyPatternBackground {
  background-color: #F4F4F5;
  padding-bottom: 0;
  margin: 0;
}

.yellowVertStrip {
  width: 3px;
  height: auto;
  background-color: #FFC107;
  margin: 0;
}

.health-title {
  font-size: 22px;
  color: #447CBA;
}

.medicalServicesCard {
  max-width: 360px;
  min-width: 360px;
  margin: 0;
  margin-bottom: 30px;
  height: auto;
  padding: 0;
  border-radius: 10px;
}

.infoParagraph {
  font-size: 20px;
  color: #707070;
}

.safeLogo {
  width: 200px;
  margin-left: auto;
  margin-right:auto;
  padding-top: 20px;
}

.icAgileLogo {
  height: 80px;
  margin-left: auto;
  margin-right:auto;
}
.scrumAllianceLogo {
  width: 180px;
  margin-left: auto;
  margin-right:auto;
  padding-top: 10px;
}
.devopsInstituteLogo {
  width: 180px;
  margin-left: auto;
  margin-right:auto;
  padding-top: 10px;
}

.kanbanUniveristyLogo {
  width: 180px;
  margin-left: auto;
  margin-right:auto;
  padding-top: 15px;
}